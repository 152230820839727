:root {
  --listing-card-min-height: unset;
  --listing-card-padding: 0 0 var(--space-4x) 0;
  --listing-card-badge-star-color: var(--color-grey-500);
  --listing-card-group-item-icon-color: var(--color-grey-500);
  --listing-card-3d-label-color: var(--color-blue-600);
  --listing-card-category-badge-background-color: var(--color-white);
  --listing-card-border-radius: var(--border-radius-2x);
  --listing-card-overflow: hidden;
  --listing-card-carousel-min-width: 343px;
  --listing-card-bookmark-filled-background-color: var(--color-blue-600);
  --listing-card-border-color: var(--color-gray-200);
  --listing-card-badge-background-color: var(--color-green-600);
  --listing-card-badge-color: var(--color-white);
}
