:root {
  --detail-page-container-vertical-margin: var(--space-8x);
  --detail-page-container-margin: var(--detail-page-container-vertical-margin) 0;
  --detail-page-footer-padding-bottom: var(--space-20x);
  --listing-info-media-unavail-divider-display: block;
  --listing-info-media-unavail-padding: 0;
  --sticky-box-top: 54px;
  --sticky-box-default-top: 15px;
  --sticky-box-offset-top: -367px;
  --sticky-box-offset-top-with-pmc: -404px;
  --sticky-box-offset-top-with-deals: -563px;
  --sticky-box-offset-top-with-pmc-deals: -600px;

  @media (--screen-md) {
    --detail-page-footer-padding-bottom: var(--space-24x);
  }

  @media (--screen-xl) {
    --detail-page-footer-padding-bottom: var(--space-3x);
  }
}
