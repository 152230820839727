:root {
  --info-section-header-open-background-color: var(--color-gray-100);
  --info-section-header-closed-background-color: var(--color-white);
  --info-section-description-closed-color: var(--color-black);
  --info-section-description-open-color: var(--color-black);
  --info-section-heading-font-family: var(--font-family-body);
  --info-section-heading-open-color: var(--color-black);
  --info-section-icon-open-color: var(--color-gray-200);
  --info-section-heading-margin-bottom: var(--space-3x);
  --info-section-icon-closed-color: var(--color-black);
  --info-section-icon-wrapper-closed-background-color: var(--color-gray-100);
  --info-section-icon-wrapper-open-background-color: var(--color-black);
  --info-section-rating-star-backdrop-color: var(--color-gray-300);
  --info-section-rating-star-color: var(--color-black);
  --info-section-rating-text-color: var(--color-black);
  --info-section-rating-source-divider-color: var(--color-gray-400);
  --info-section-rating-source-color: var(--color-black);
  --info-section-button-display: inline-block;
  --info-section-header-padding: var(--space-5x) var(--space-6x);
  --info-section-header-padding-medium: 0;
}
