:root {
  --gallery-modal-header-favorite-share-button-background-color: var(
    --color-white
  );
  --gallery-modal-header-favorite-share-button-color: var(--color-blue-600);
  --gallery-modal-header-favorite-share-button-hover-color: var(
    --color-blue-500
  );
  --gallery-modal-header-favorite-share-button-box-shadow: 0 3px 5px
    var(--color-gray-200);
}
