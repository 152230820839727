:root {
  --sticky-footer-icon-cta-active-color: var(--color-gray-500);
  --sticky-footer-icon-cta-hover-color: var(--color-gray-500);
  --sticky-footer-icon-cta-base-color: var(--color-gray-500);
  --sticky-footer-icon-cta-background-color: var(--color-white);
  --sticky-footer-icon-cta-hover-background-color: var(--color-white);
  --sticky-footer-icon-cta-filled-color: var(--color-red-500);
  --sticky-footer-icon-cta-margin: unset;
  --sticky-footer-icon-cta-box-shadow: unset;
}
