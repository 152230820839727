@custom-media --screen-key-info-google-rating-horizontal (min-width: 400px);

:root {
  --key-info-background-color: var(--color-white);
  --key-info-border-bottom: none;
  --key-info-main-color: var(--color-black);
  --key-info-secondary-color: var(--color-gray-500);
  --key-info-anchor-color-active: var(--color-blue-600);
  --key-info-anchor-color-hover: var(--color-blue-500);
  --key-info-anchor-color-default: var(--color-blue-600);
  --key-info-reaction-active-color: var(--color-blue-400);
  --key-info-reaction-hover-color: var(--color-blue-500);
  --key-info-reaction-base-color: var(--color-blue-600);
  --key-info-reaction-background-color: transparent;
  --key-info-reaction-box-shadow: 0 3px 5px var(--color-gray-200);
  --key-info-reaction-filled-background-color: var(--color-red-500);
  --key-info-rating-text-display: var(--color-gray-500);
  --key-info-rating-text-display-lg: var(--color-gray-500);
  --key-info-bookmark-filled-background-color: var(--color-blue-600);
  --key-info-padding-bottom: var(--space-7x);
  --key-info-padding-bottom-inactive: var(--space-7x);
  --off-market-badge-background-color: var(--color-gray-200);
  --off-market-badge-font-color: var(--color-black);

  --key-info-verified-container-display: block;
  --key-info-price-rating-margin-bottom: var(--space-2x);
  --key-info-price-font: var(--font-size-lg);
  --key-info-heading-font: var(--font-size-2xl);

  @media (--screen-sm) {
    --key-info-heading-font: var(--font-size-4xl);
  }

  @media (--screen-lg) {
    --key-info-price-font: var(--font-size-xl);
  }
}
