:root {
  --search-combobox-results-list-font-size: var(--font-size-lg);
  --search-combobox-icon-font-size: var(--font-size-2xl);
  --search-combobox-icon-min-width: var(--space-6x);
  --search-combobox-results-list-vertical-border: var(--space-5x) solid
    var(--color-white);
  --search-combobox-input-padding: var(--space-4x);
  --search-combobox-list-wrap-padding: 0 var(--space-1x) var(--space-1x)
    var(--space-1x);
  --search-combobox-list-wrap-horizontal-border: var(--space-2x) solid
    var(--color-white);
  --search-combobox-list-wrap-box-shadow: -1px 0 0 0 var(--color-gray-200),
    1px 0 0 0 var(--color-gray-200), 0 1px 0 0 var(--color-gray-200),
    0 -1px 0 0 var(--color-gray-200);
  --search-combobox-list-wrap-outline: 0;
  --search-combobox-list-wrap-left: auto;
  --search-combobox-list-wrap-divider-display: none;
  --search-combobox-list-wrap-visible-border-radius: 0.25rem;
  --search-combobox-list-item-padding: var(--space-4x);
  --search-combobox-list-item-wrap-padding: 0;
  --search-combobox-results-header-border-top: none;
  --search-combobox-results-header-padding: var(--space-5x) var(--space-2x)
    var(--space-2x);
  --search-combobox-results-header-padding-first: 0 var(--space-4x);
  --search-combobox-search-button-font-size: var(--font-size-lg);
  --search-combobox-close-button-z-index: var(--z-index-2x);
  --search-combobox-close-button-color: var(--color-blue-600);
  --search-combobox-close-button-color-hover: var(--color-blue-500);
  --search-combobox-close-button-color-active: var(--color-blue-500);
  --search-combobox-button-background-color: var(--color-red-500);
  --search-combobox-button-background-color-active: var(--color-red-400);
  --search-combobox-button-border-radius: var(--border-radius-2x);
  --search-combobox-list-item-hover-color: var(--color-gray-100);
  --search-combobox-list-item-highlighted-color: var(--color-gray-100);
  --search-combobox-list-item-icon-color: var(--color-red-500);
  --search-combobox-input-wrap-background: var(--color-white);
  --search-combobox-back-button-color: var(--color-red-500);
  --search-combobox-input-wrap-border: 1px solid var(--color-gray-200);
  --search-combobox-header-search-input-background: var(--color-gray-100);
  --search-combobox-header-search-input-border: none;
  --search-combobox-header-border-radius: 100px;
  --search-combobox-header-visible-border-radius: 100px;
  --search-combobox-root-border-radius: var(--border-radius-2x);
  --search-combobox-root-padding: 0;
  --search-combobox-root-border-color: transparent;
  --search-combobox-root-border-style: none;
}
