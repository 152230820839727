:root {
  --seo-free-form-width: 100%;
  --seo-free-form-height: 100%;
  --seo-free-form-overflow: hidden;
  --seo-free-form-font-size: var(--font-size-body);
  --seo-free-form-mobile-padding: var(--space-6x);
  --seo-free-form-padding: var(--space-6x) 0;
  --seo-free-form-text-margin: var(--space-2-5x) 0;
  --seo-free-form-text-color: var(--color-gray-500);
  --seo-free-form-h2-mobile-font-size: var(--font-size-2xl);
  --seo-free-form-h2-font-size: var(--font-size-4xl);
  --seo-free-form-h3-font-size: var(--font-size-lg);
  --seo-free-form-lists-padding: 0;
  --seo-free-form-lists-list-style: none;
}
