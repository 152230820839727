:root {
  --pet-policies-description-section-padding: 0 0 var(--space-5x);
  --pet-policies-description-section-width: auto;
  --pet-policies-description-section-border-bottom: 1px solid
    var(--color-gray-200);
  --pet-policies-description-section-margin: 0 0 var(--space-5x);
  --pet-policies-description-list-item-margin-top: var(--space-4x);
  --pet-policies-label-font-weight: var(--font-weight-semibold);
  --pet-policies-label-margin: 0 0 var(--space-2x);
  --pet-policies-label-display: block;
  --pet-policies-label-color: var(--color-black);
  --pet-policies-comment-display: block;
  --pet-policies-pet-header-margin-bottom: var(--space-5x);
  --pet-policies-formatted-margin: 0;

  @media (--screen-md) {
    --pet-policies-description-section-padding: 0 var(--space-4x) 0 0;
    --pet-policies-description-section-width: 33%;
  }
}
