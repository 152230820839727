:root {
  --modal-panel-wrap-padding: var(--space-4x);
  --modal-panel-wrap-align-items: center;
  --modal-panel-background: var(--color-white);
  --modal-panel-border-radius: 0;
  --modal-panel-shadow: 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%),
    0 4px 8px -2px rgb(0 0 0 / 25%);
  --modal-padding: var(--space-6x) var(--space-4x) var(--space-4x);
  --modal-width: 100%;
  --modal-max-width: 100%;
  --modal-height: 100%;
  --modal-max-height: 100%;
  --modal-panel-align-items: normal;
  --modal-panel-gap: 0;
  --modal-panel-overflow: auto;
  --modal-panel-position: fixed;
  --modal-close-button-font-size: var(--font-size-2xl);
  --modal-close-button-padding: var(--space-5x) var(--space-6x);
  --modal-close-button-color: var(--color-blue-600);
  --modal-close-button-color-hover: var(--color-blue-500);
  --modal-close-button-position: absolute;
  --modal-close-button-top: 0;
  --modal-close-button-right: 0;
  --modal-panel-wrap-min-height: 100%;
  --modal-panel-wrap-height: auto;
  --modal-container-overflow: auto;
  --modal-clear-button-text-decoration: underline;

  @media (--screen-md) {
    --modal-panel-border-radius: var(--border-radius-2x);
    --modal-padding: var(--space-10x) var(--space-8x) var(--space-8x);
    --modal-panel-position: relative;
  }
}
