:root {
  --saved-search-edit-button-background-color: var(--color-red-50);
  --saved-search-edit-button-background-color-hover: var(--color-red-90);
  --saved-search-edit-button-border-color: var(--color-red-500);
  --saved-search-edit-button-border-color-hover: var(--color-red-600);
  --saved-search-save-button-font-size: var(--font-size-body);
  --saved-search-button-background-color: var(--button-background-color-base);
  --saved-search-button-background-color-hover: var(
    --button-background-color-hover
  );
  --saved-search-button-background-color-active: var(
    --button-background-color-active
  );
}
