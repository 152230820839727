:root {
  --map-toggle-position: absolute;
  --map-toggle-width: var(--space-10x);
  --map-toggle-height: var(--space-10x);
  --map-toggle-padding: 0;
  --map-toggle-top: unset;
  --map-toggle-right: var(--space-5x);
  --map-toggle-left: unset;
  --map-toggle-bottom: unset;
  --map-toggle-font-size: var(--font-size-xl);
}
