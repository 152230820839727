:root {
  --gallery-reaction-active-color: var(--color-gray-500);
  --gallery-reaction-hover-color: var(--color-white);
  --gallery-reaction-base-color: var(--color-white);
  --gallery-reaction-background-color: rgb(0 0 0 / 50%);
  --gallery-reaction-filled-background-color: var(--color-white);
  --gallery-pill-background-color: var(--color-white);
  --gallery-pill-border-color: var(--color-gray-200);
  --gallery-pill-box-shadow: 0 4px 8px -2px rgba(0 0 0 / 10%);
  --gallery-pill-color: var(--color-blue-600);
  --gallery-pill-arrow-border-color: var(--color-gray-200);
  --gallery-reaction-arrow-button-color: var(--color-black);
  --gallery-reaction-arrow-button-hover-color: var(--color-red-600);
  --gallery-carousel-slide-border-radius: var(--border-radius-2x);
  --gallery-zindex: initial;
}
