:root {
  /**
    * This should map to color gray 600, but this is not correctly exported from Figma
    to be generated via design tokens https://www.figma.com/file/U0KMB0vo2At4nyX5pTiBiZ/Rent_Enhanced-Lead-Form_20231010?node-id=3788%3A42687&mode=dev
    */
  --selected-hover-color: #373839;
  --toggle-button-color: var(--color-black);
  --toggle-button-hover-color: var(--color-black);
  --toggle-button-color-selected: var(--color-white);
  --toggle-button-color-selected-hover: var(--color-white);
  --toggle-button-border-color: var(--color-gray-200);
  --toggle-button-hover-border-color: var(--color-gray-200);
  --toggle-button-selected-hover-border-color: var(--selected-hover-color);
  --toggle-button-border-color-selected: var(--color-black);
  --toggle-button-hover-background-color: var(--color-gray-200);
  --toggle-button-background-color-selected: var(--color-black);
  --toggle-button-background-color-selected-hover: var(--selected-hover-color);
  --toggle-button-font-weight-selected: var(--font-weight-medium);
  --toggle-button-border-width: 2px;
  --toggle-button-modal-color: var(--color-gray-500);
  --toggle-button-modal-hover-color: var(--color-black);
  --toggle-button-modal-border-color: var(--color-gray-200);
  --toggle-button-modal-hover-border-color: var(--color-gray-200);
  --toggle-button-font-weight: var(--font-weight-semibold);
  --toggle-button-min-height: var(--space-10x);
  --toggle-button-group-gap: var(--space-3x);
}
