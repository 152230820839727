:root,
::before,
::after {
  --checkbox-bg-color: var(--color-white);
  --checkbox-bg-color-filled: var(--color-black);
  --checkbox-border-color: var(--color-black);
  --checkbox-border-color-filled: var(--color-black);
  --checkbox-border-width: 2px;
  --checkbox-size: 16px;
  --checkbox-text-color-filled: var(--color-white);
  --checkbox-text-color: var(--color-white);
  --checkbox-pill-background-color: var(--color-blue-100);
  --checkbox-pill-color: var(--color-blue-600);
}
