:root {
  --search-results-header-anchor-text-color: var(--color-gray-500);
  --search-results-header-filter-button-color-hover: var(--color-black);
  --search-results-header-filter-button-background-color-hover: var(
    --color-gray-200
  );
  --search-results-header-filter-button-icon-color: var(--color-blue-600);
  --search-results-header-outline-button-base-color: var(--color-blue-600);
  --search-results-header-outline-button-border-color: var(--color-blue-600);
  --search-results-header-top: 100px;
  --search-results-header-text-decoration: none;
  --search-results-header-padding: var(--space-2-5x) var(--space-3x);
  --search-results-header-gap: var(--space-2x);
  --search-results-header-background-color: var(--color-white);
  --search-results-header-border: 1px solid var(--color-gray-200);
  --search-results-header-button-font-size: 13px;
  --search-results-header-button-height: var(--space-10x);
  --search-results-header-input-font-size: var(--font-size-body);
  --search-results-header-input-container-border: 1px solid
    var(--color-gray-200);
}
