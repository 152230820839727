:root {
  --listing-card-preview-card-background-color-dark: var(--color-white);
  --listing-card-preview-card-background-color-hover-dark: var(--color-black);
  --listing-card-preview-card-background-color-light: var(--color-white);
  --listing-card-preview-card-background-color-hover-light: var(
    --color-gray-100
  );
  --listing-card-preview-info-card-color-dark: var(--color-black);
  --listing-card-preview-info-card-color-hover-dark: var(--color-white);
  --listing-card-preview-info-card-color-light: var(--color-black);
  --listing-card-preview-info-card-details-text-color-light: var(
    --color-gray-500
  );
  --listing-card-preview-info-card-details-text-color-dark: var(--color-black);
  --listing-card-preview-border-radius: var(--border-radius-2x);
  --listing-card-preview-border: 1px solid var(--color-gray-200);
  --listing-card-preview-primary-color: var(--color-red-500);
  --listing-card-preview-info-card-shadow: none;
  --listing-card-info-section-height: unset;
  --listing-card-preview-image-aspect-ratio: 5/3;
  --listing-card-preview-cta-border-radius: var(--button-rounded-border-radius);
}
