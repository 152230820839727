:root {
  --content-grid-section-container-display: flex;
  --content-grid-last-item-list-display: flex;
  --content-grid-last-item-list-width: 50%;
  --content-grid-last-item-list-column-count: 3;
  --content-grid-list-item-margin: var(--space-3x) 0 0;
  --content-grid-list-display: block;
  --content-grid-list-style-type: none;
  --content-grid-list-width: 50%;
  --content-grid-list-padding: 0;
  --content-grid-list-margin: 0;

  @media (--screen-md) {
    --content-grid-last-item-list-display: block;
    --content-grid-last-item-list-width: 100%;
    --content-grid-list-item-margin: var(--space-1x) 0 0 var(--space-5x);
    --content-grid-list-style-type: disc;
    --content-grid-list-width: 100%;
  }
}
