:root {
  --filter-card-display: flex;
  --filter-card-flex-direction: column;
  --filter-card-title-order: 2;
  --filter-card-image-order: 1;
  --filter-card-title-width: auto;
  --filter-card-image-width: auto;
  --filter-card-title-padding-top: 0;
  --filter-card-title-padding-bottom: 0;
  --filter-card-image-height: auto;
  --filter-card-image-padding-top: 0;
  --filter-card-apartment-count-padding: var(--filter-card-title-padding-top)
    var(--space-32x) var(--filter-card-title-padding-bottom) var(--space-6x);

  --filter-card-toggle-button-border-width: 2px;

  --filter-card-toggle-button-color: var(--color-red-500);
  --filter-card-toggle-button-hover-color: var(--color-red-600);

  --filter-card-toggle-button-border-color: var(--color-red-500);
  --filter-card-toggle-button-hover-border-color: var(--color-red-600);
  --filter-card-toggle-button-border-color-selected: var(--color-red-500);
  --filter-card-toggle-button-selected-hover-border-color: var(--color-red-600);

  --filter-card-toggle-button-hover-background-color: var(--color-white);
  --filter-card-toggle-button-background-color-selected: var(--color-red-500);
  --filter-card-toggle-button-background-color-selected-hover: var(
    --color-red-600
  );
  --filter-card-cta-border-radius: var(--button-rounded-border-radius);
}
