:root {
  --nearby-properties-background-color: var(--color-white);
  --nearby-properties-heading-color: var(--color-black);
  --nearby-properties-copy-color: var(--color-black);
  --nearby-properties-grid-padding: var(--space-9x) var(--space-6x)
    var(--space-2x) var(--space-6x);
  --nearby-properties-header-margin: 62px var(--space-6x) 0 0;
  --nearby-properties-grid-display: flex;
  --nearby-properties-grid-row-gap: var(--space-10x);
  --nearby-properties-copy-display: none;
  --nearby-properties-grid-margin-top: 0;
  --nearby-properties-heading-font-size: var(--font-size-2xl);
  --nearby-properties-heading-margin: 0 0 0 var(--space-6x);
  --nearby-properties-line-height: normal;
  --nearby-properties-letter-spacing: -0.72px;

  @media (--screen-md) {
    --nearby-properties-header-margin: var(--space-11x) var(--space-6x) 0 0;
  }

  @media (--screen-lg) {
    --nearby-properties-header-margin: var(--space-11x) var(--space-6x) 0 0;
    --nearby-properties-grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (--screen-xl) {
    --nearby-properties-grid-display: grid;
  }
}
