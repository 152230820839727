:root {
  --highlights-highlight-color: var(--color-gray-500);
  --highlights-highlight-star-color: var(--color-gray-500);
  --highlights-highlight-star-margin: 0 var(--space-1x) 0 0;
  --highlights-highlight-star-width: var(--space-4x);
  --highlights-highlight-star-height: var(--space-4x);
  --highlights-highlight-item-width: fit-content;
  --highlights-highlight-item-height: unset;
  --highlights-highlight-item-white-space: nowrap;
  --highlights-highlight-item-display: flex;
  --highlights-highlight-item-align-items: center;
  --highlights-highlight-item-border-radius: var(--border-radius-full);
  --highlights-highlight-item-background-color: var(--color-gray-100);
  --highlights-highlight-item-color: var(--highlights-highlight-color);
  --highlights-highlight-item-padding: var(--space-1x) var(--space-3x);
}
