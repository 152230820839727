:root {
  --horizontal-scroll-arrow-button-color: var(--color-red-500);
  --horizontal-scroll-arrow-button-hover-color: var(--color-red-600);
  --horizontal-scroll-arrow-button-position: absolute;
  --horizontal-scroll-arrow-button-margin: -14px 0 0 0;
  --horizontal-scroll-arrow-button-hidden-display: none;
  --horizontal-scroll-arrow-button-direction: -10px;
  --horizontal-scroll-arrow-button-box-shadow: var(--shadow-200);
  --horizontal-scroll-arrow-button-dimensions: var(--space-7x);
  --horizontal-scroll-arrow-button-top: 0;
  --horizontal-scroll-arrow-button-icon-dimensions: var(--space-4x);
}
