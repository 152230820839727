:root,
::before,
::after {
  --rating-size: var(--font-size-sm);
  --rating-star-size: 15px;
  --rating-star-letter-spacing: 0;
  --rating-star-backdrop-content: '\2605\2605\2605\2605\2605';
  --rating-star-backdrop-color: var(--color-gold-200);
  --rating-star-color: var(--color-gold-500);
  --rating-star-content: '\2605\2605\2605\2605\2605';
  --rating-text-color: var(--color-gray-500);
  --rating-text-display: inline;
  --rating-star-key-info-backdrop-color: var(--color-gold-200);
}
