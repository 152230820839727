:root {
  --table-font-weight: var(--font-weight-normal);
  --table-background-color-odd: none;
  --table-background-color-even: var(--color-gray-100);
  --table-border: 1px solid var(--color-gray-200);
  --table-text-align: center;
  --table-tbody-td-padding: var(--space-3x) 0;
  --table-tbody-td-color: var(--color-gray-500);
  --table-tbody-td-first-child-padding: 0;
  --table-tbody-td-first-child-color: var(--color-gray-700);
}
