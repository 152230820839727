:root {
  --search-results-li-highlight-box-shadow: 0 0 0 var(--space-1x)
    var(--color-red-300);
  --search-results-container-mobile-padding: var(--space-1x) var(--space-2x)
    var(--space-4x);
  --search-results-container-desktop-padding: var(--space-1x) var(--space-6x)
    var(--space-4x);
  --search-results-listing-card-min-height: 484px;
  --search-results-listing-card-skeleton-height: 484px;
}
