@layer reset {
  /* Use a more-intuitive box-sizing model */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default margin */
  * {
    margin: 0;
  }

  /* Allow percentage-based heights in the application */
  html,
  body {
    height: 100%;
  }

  /* Improve text rendering and readability */
  body {
    font-family: var(--font-family-body);
    line-height: var(--leading-body);
    -webkit-font-smoothing: antialiased;
  }

  /* Improve media defaults */
  img,
  picture,
  video,
  canvas {
    display: block;
    max-width: 100%;
  }

  /* Remove built-in form typography styles */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Avoid text overflows */
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  /* Create a root stacking context */
  /* stylelint-disable-next-line selector-id-pattern */
  #root,
  #__next {
    isolation: isolate;
  }

  /* Hide native calendar icons on date inputs. */
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  /* Prevent zoom-in when user quickly taps button (such as for the gallery navigation buttons) */
  button,
  input[type='button'] {
    touch-action: manipulation;
  }

  /* Reset button styles */
  button {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  /* Don't show webkit's blue autofill background */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset;
  }

  button:focus-visible,
  button:focus-visible:focus,
  input[type='checkbox']:focus-visible,
  input[type='radio']:focus-visible,
  input[type='submit']:focus-visible,
  select:focus-visible,
  form:focus-visible,
  *[role='button']:focus-visible,
  *[role='tabpanel']:focus-visible,
  *[role='listbox']:focus-visible,
  .toggleButton:focus-visible,
  a:focus-visible,
  input[type='radio']:focus-visible + label {
    outline: 2px solid var(--color-focus-outline);
    box-shadow: 0 0 0 4px var(--color-focus-shadow);
    outline-offset: 0;
  }

  button[data-headlessui-state='open']:focus-visible {
    outline: none;
    box-shadow: none;
  }

  /* This applies to the Tab elements deemed for non accessibilities users only */
  .mouseVisited[role='tablist'] > button[role='tab'] {
    outline: none;
    box-shadow: none;
  }

  dialog {
    border: 0;
  }

  /* https://github.com/whatwg/html/issues/7732 */
  body:has(dialog[open]) {
    overflow: hidden;
  }
}
