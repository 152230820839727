:root {
  --heading-with-heading-font-text-transform: uppercase;
  --heading-with-heading-font-font-weight: 600;
  --heading-with-body-font-text-transform: capitalize;
  --heading-with-body-font-font-weight: 600;
  --heading-text-transform: none;
  --heading-text-align: inherit;
  --heading-border-bottom: 0;
  --heading-font-size: var(--font-size-2xl);
  --heading-font-weight: 600;
  --heading-padding: 0;
  --heading-margin: 0;
  --heading-color: var(--color-black);
  --heading-letter-spacing: 0;
  --heading-line-height: var(--leading-tight);
}
