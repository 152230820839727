:root {
  --state-lander-page-background: var(--color-white);
  --state-lander-page-breadcrumbs-anchor-active-color: var(--color-blue-700);
  --state-lander-page-breadcrumbs-anchor-color: var(--color-blue-600);
  --state-lander-page-breadcrumbs-anchor-hover-color: var(--color-blue-500);
  --state-lander-page-breadcrumbs-headline-color: var(--color-gray-500);
  --state-lander-page-city-links-text-color: var(--color-blue-600);
  --state-lander-page-color: var(--color-black);
  --state-lander-page-divider-color: var(--color-gray-300);
  --state-lander-page-hero-list-color: var(--color-gray-500);
  --state-lander-page-overview-text-color: var(--color-gray-500);
  --state-lander-page-search-combo-box-border-color: var(--color-gray-200);
}
