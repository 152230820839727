:root {
  --home-page-background: transparent;
  --home-page-color: inherit;
  --home-page-container-max-width: 1325px;
  --home-page-font-size: var(--font-size-body);
  --home-page-heading-font-size-mobile: var(--font-size-4xl);
  --home-page-heading-font-size-desktop: var(--font-size-5xl);
  --home-page-heading-font-weight-desktop: 700;
  --home-page-heading-font-weight-mobile: 600;
  --home-page-overflow: visible;
}
