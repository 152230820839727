:root {
  --list-property-home-button-width: fit-content;
  --list-property-home-button-border-radius: var(--border-radius-6x);
  --list-property-home-container-flex-direction: column;
  --list-property-page-background: var(--color-gray-90);
  --list-property-page-background-value-prop: var(--color-white);
  --list-property-page-theme-background: transparent;
  --list-property-page-theme-color: var(--color-black);
  --list-property-logos-margin: 21px 0;
  --list-property-logos-gap: var(--space-5x);
  --list-property-block-padding: var(--space-14x) var(--space-6x);
  --list-property-home-text-max-width: 363px;
  --list-property-block-headline-margin-bottom: var(--space-8x);
  --list-property-value-props-padding: var(--space-8x) 0;

  @media (--screen-md) {
    --list-property-block-wrapper-gap: var(--space-16x);
    --list-property-home-container-flex-direction: row-reverse;
    --list-a-property-text-image-block-container-width: 448px;
    --list-property-logos-margin: 0;
  }
}
