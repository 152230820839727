:root {
  --footer-margin: 0;
  --footer-background: var(--color-black);
  --footer-color: var(--color-white);
  --footer-heading-label-color: var(--color-white);
  --footer-social-icon-background: var(--color-red-500);
  --footer-social-icon-background-hover: var(--color-red-600);
  --footer-social-icon-background-active: var(--color-red-400);
  --footer-social-icon-color: var(--color-white);
  --footer-social-icon-size: 24px;
  --footer-logo-color: var(--color-white);
  --footer-anchor-text-color: var(--color-blue-300);
  --footer-anchor-text-color-hover: var(--color-blue-400);
  --footer-anchor-text-color-active: var(--color-blue-500);
  --sticky-footer-background: var(--color-red-500);
  --footer-link-section-chevron-icon-color: var(--color-blue-500);
  --footer-link-section-border: 1px solid var(--color-gray-500);
  --footer-logo-max-width: 245px;
  --footer-elements-flex-direction: row;
  --footer-bottom-justify-content: flex-start;
  --footer-bottom-width: 668px;
  --footer-padding-bottom: var(--space-3x);
  --footer-bottom-gap: 6px;
  --footer-middle-gap: 66px;
  --footer-top-padding: var(--space-10x) var(--space-6x) var(--space-8x);
  --footer-social-links-justify-content: flex-start;
  --footer-social-links-gap: var(--space-3x);

  @media (--screen-md) {
    --footer-bottom-gap: var(--space-3x);
    --footer-top-padding: var(--space-10x) 0 var(--space-8x);
  }
}
