:root {
  --page-header-background: var(--color-white);
  --page-header-text: var(--color-black);
  --page-header-height: 50px;
  --page-header-logo-color: var(--color-black);
  --page-header-logo-height: 22px;
  --page-header-border-bottom: 1px solid var(--color-gray-200);
  --page-header-position: relative;
  --page-header-back-color: var(--color-white);
  --page-header-back-background-color: var(--color-red-500);
  --page-header-back-background-color-active: var(--color-red-600);
  --page-header-links-background-color: inherit;
  --page-header-links-color: var(--color-gray-500);
  --page-header-links-color-hover: var(--color-blue-500);
  --page-header-links-color-active: var(--color-blue-500);
  --page-header-links-font-weight: var(--font-weight-medium);
  --page-header-links-text-decoration: none;
  --page-header-links-text-decoration-hover: underline;
  --page-header-favorite-color: var(--color-white);
  --page-header-favorite-color-hover: var(--color-blue-500);
  --page-header-favorite-color-filled: var(--color-red-400);
  --page-header-favorite-text-decoration: underline;
  --page-header-logo-width: 128px;
  --page-header-links-padding-left: var(--space-1x);

  @media (--screen-sm) {
    --page-header-logo-height: 38px;
    --page-header-logo-width: 221px;
  }
}
