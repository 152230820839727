@layer tokens {
  /* prettier-ignore */
  :root, ::before, ::after {
    /* spacing scale */
    --space-none: 0;        /* 0px */
    --space-px: 1px;        /* 1px */
    --space-0-5x: 0.125rem; /* 2px */
    --space-1x: 0.25rem;    /* 4px */
    --space-1-5x: 0.375rem; /* 6px */
    --space-2x: 0.5rem;     /* 8px */
    --space-2-5x: 0.625rem; /* 10px */
    --space-3x: 0.75rem;    /* 12px */
    --space-3-5x: 0.875rem; /* 14px */
    --space-4x: 1rem;       /* 16px */
    --space-4-5x: 1.125rem; /* 18px */
    --space-5x: 1.25rem;    /* 20px */
    --space-5-5x: 1.375rem; /* 22px */
    --space-6x: 1.5rem;     /* 24px */
    --space-6-5x: 1.625rem; /* 26px */
    --space-7x: 1.75rem;    /* 28px */
    --space-7-5x: 1.875rem; /* 28px */
    --space-8x: 2rem;       /* 32px */
    --space-9x: 2.25rem;    /* 36px */
    --space-10x: 2.5rem;    /* 40px */
    --space-10-5x: 2.625rem;/* 42px */
    --space-11x: 2.75rem;   /* 44px */
    --space-12x: 3rem;      /* 48px */
    --space-14x: 3.5rem;    /* 56px */
    --space-15x: 3.75rem;   /* 60px */
    --space-16x: 4rem;      /* 64px */
    --space-18x: 4.5rem;    /* 72px */
    --space-20x: 5rem;      /* 80px */
    --space-24x: 6rem;      /* 96px */
    --space-28x: 7rem;      /* 112px */
    --space-29x: 7.25rem;   /* 116px */
    --space-31x: 7.75rem;   /* 124px */
    --space-32x: 8rem;      /* 128px */
    --space-36x: 9rem;      /* 144px */
    --space-40x: 10rem;     /* 160px */
    --space-44x: 11rem;     /* 176px */
    --space-48x: 12rem;     /* 192px */
    --space-52x: 13rem;     /* 208px */
    --space-56x: 14rem;     /* 224px */
    --space-60x: 15rem;     /* 240px */
    --space-64x: 16rem;     /* 256px */
    --space-72x: 18rem;     /* 288px */
    --space-80x: 20rem;     /* 320px */
    --space-96x: 24rem;     /* 384px */

    /* z-index levels */
    --z-index-0: 0;
    --z-index-1x: 1;
    --z-index-2x: 2;
    --z-index-3x: 3;
    --z-index-4x: 4;
    --z-index-5x: 5;
    --z-index-6x: 6;
    --z-index-7x: 7;
    --z-index-auto: auto;

    /* border radii */
    --border-radius-none: 0;
    --border-radius-1x: var(--space-0-5x);
    --border-radius-2x: var(--space-1x);
    --border-radius-3x: var(--space-1-5x);
    --border-radius-4x: var(--space-2x);
    --border-radius-5x: var(--space-2-5x);
    --border-radius-6x: var(--space-3x);
    --border-radius-7x: var(--space-3-5x);
    --border-radius-8x: var(--space-4x);
    --border-radius-full: 100px;
    --border-radius-circle: 100%;

    /* shadows */
    /* stylelint-disable color-function-notation */
    --shadow-600: 0 4px 8px -2px rgba(0, 0, 0, 10%);

    --color-focus-outline: var(--color-green-200);
    --color-focus-shadow: var(--color-green-600);
  }
}
